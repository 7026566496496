<script setup lang="ts">
import { Payee } from '/~/types/api'
import modal from '/~/core/mdl'
import PayeeIcon from '/~/components/app/payee-icon.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMenu from '/~/components/base/menu/base-menu.vue'
import { useLocalization } from '/~/composables/localization'
import { useBankAccountForm } from '/~/composables/payment-methods/use-bank-account-form'
import PayToRowStatus from './pay-to-row-status.vue'
import { usePayToRowComponent } from './use-pay-to-row-component'

const props = withDefaults(
  defineProps<{
    payee: Payee
    enableContextMenu?: boolean
  }>(),
  {
    enableContextMenu: true,
  }
)

const emit = defineEmits<(event: 'click', payload: Payee) => void>()
const { translate, formatBsb } = useLocalization()
const { accountPresentation } = useBankAccountForm()

const uiHandlers = {
  showEdit: () => {
    modal.show('edit-payee', {
      props: { payee: props.payee },
    })
  },
  showDelete: () => {
    modal.show('delete-payee', {
      props: { payee: props.payee },
    })
  },
}

const { menuItems, formatBusinessNumber, isPayeeActionsDisabled } =
  usePayToRowComponent(props.payee, uiHandlers)
</script>

<template>
  <div
    class="px-5 py-4"
    @click="isPayeeActionsDisabled && $event.stopImmediatePropagation()"
  >
    <div
      :class="[
        'grid w-full grid-cols-[auto_1.5fr_repeat(2,minmax(0,1fr))_auto] gap-2.5',
        isPayeeActionsDisabled ? 'pointer-events-none opacity-50' : '',
        !enableContextMenu ? 'cursor-pointer' : '',
      ]"
      @click="emit('click', payee)"
    >
      <payee-icon :payee="payee" />
      <div class="flex items-center font-bold text-eonx-neutral-800">
        {{ payee.name }}
      </div>
      <template v-if="payee.type === 'bpay'">
        <div>
          <p class="text-sm text-eonx-neutral-600">Biller Code</p>
          <p class="truncate text-eonx-neutral-800">
            {{ payee.billerCode }}
          </p>
        </div>
        <div>
          <p class="text-sm text-eonx-neutral-600">Reference</p>
          <p class="truncate text-eonx-neutral-800">
            {{ payee.reference }}
          </p>
        </div>
      </template>
      <template v-else-if="payee.type === 'bankAccount'">
        <div>
          <p class="text-sm text-eonx-neutral-600">
            {{ translate('bankAccount.detailsTitle') }}
          </p>
          <p class="truncate text-eonx-neutral-800">
            <template v-if="accountPresentation.bsb">
              {{ formatBsb(payee.bsb || '') }},
            </template>
            {{ payee.accountNumber }}
          </p>
        </div>
        <div>
          <p class="text-sm text-eonx-neutral-600">
            {{ translate('payment.businessNumber') }}
          </p>
          <p class="truncate text-eonx-neutral-800">
            {{ formatBusinessNumber(payee.abn) }}
          </p>
        </div>
      </template>
      <template v-else>
        <!-- Fill grid cells to keep layout consistent -->
        <div></div>
        <div></div>
      </template>

      <!-- TODO Add Points section -->
      <div class="flex items-center justify-center">
        <template v-if="enableContextMenu">
          <base-menu
            v-if="!payee.isProviderPayee"
            data-testid="pay-to-row-context-menu"
            :items="menuItems"
            position="right"
          >
            <template #trigger>
              <div class="flex">
                <base-icon svg="plain/more-v2" size="sme" />
              </div>
            </template>
          </base-menu>
        </template>
        <base-icon
          v-else
          :size="14"
          svg="v2/custom/chevron-right-outline"
          class="m-auto hidden justify-self-center text-sm text-eonx-neutral-800 sm:block"
        />
      </div>
    </div>
    <pay-to-row-status
      :payee="payee"
      class="mt-4 pr-6"
      @click-remove="uiHandlers.showDelete(payee)"
    />
  </div>
</template>
