import { computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import { Payee } from '/~/types/api'
import bottomSheet from '/~/core/bottom-sheet'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'

export function usePayToRowComponent(
  payee: Payee,
  uiHandlers: {
    showEdit: (payee: Payee) => void
    showDelete: (payee: Payee) => void
  } = {
    showEdit: () => {},
    showDelete: () => {},
  }
) {
  const router = useRouter()
  const { formatBusinessNumber } = useLocalization()

  const menuItems = computed(() => [
    {
      icon: 'plain/dollar-v3',
      content: 'Pay now',
      hidden: false,
      disabled: false,
      click: () => {
        handlePayNow(payee)
      },
    },
    {
      icon: 'plain/edit2',
      content: 'Edit Payee',
      hidden: false,
      disabled: false,
      click: () => uiHandlers.showEdit(payee),
    },
    {
      icon: 'trash',
      content: 'Delete Payee',
      hidden: false,
      disabled: false,
      click: () => uiHandlers.showDelete(payee),
    },
  ])

  function handlePayNow(payee: Payee) {
    const { isBillPaymentsV15Template } = useProvider()

    if (isBillPaymentsV15Template.value) {
      router.push({
        name: 'payments-make',
        params: {
          payingTo: {
            payeeId: payee.id,
          },
        },
      })
      return
    }

    const options = {
      props: {
        payee,
      },
      on: {
        confirm: () => {
          router.push({
            name: 'payment-checkout',
          })
        },
      },
    }

    if (ui.mobile) {
      bottomSheet.show('pay', options)
    } else {
      modal.show('pay', options)
    }
  }

  const isPayeeActionsDisabled = computed(() => {
    return payee.status === 'pending' || payee.status === 'rejected'
  })

  return {
    menuItems,
    formatBusinessNumber,
    isPayeeActionsDisabled,
    handlePayNow,
  }
}
