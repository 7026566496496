<script setup lang="ts">
import { watchEffect } from 'vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import EmptyState from '/~/components/empty-state/empty-state.vue'
import { useAccess } from '/~/composables/access/use-access'
import { usePaymentsPayeesComponent } from './use-payments-payees-component'

const props = withDefaults(
  defineProps<{ enableContextMenu?: boolean; searchString: string }>(),
  {
    enableContextMenu: true,
  }
)

const {
  payeesGroups,
  loading,
  isEmpty,
  isEmptySearch,
  enabledAddNewButton,
  searchString,
  handleAddNewPayeeClick,
} = usePaymentsPayeesComponent()
const { isCheckingIdentityVerification, withAccessCheck } = useAccess()

watchEffect(() => {
  searchString.value = props.searchString
})
</script>

<template>
  <div class="flex h-full w-full flex-col overflow-hidden bg-eonx-neutral-50">
    <div v-if="loading" class="flex-center flex items-center pb-8">
      <base-loader fullwidth size="xl" />
    </div>

    <div v-else class="flex grow flex-col overflow-y-auto pb-8">
      <empty-state
        v-if="isEmptySearch"
        icon="heroicons/solid/user"
        class="m-auto"
      >
        <template #title>
          {{ !isEmpty ? 'No matching payees found' : 'No payees listed' }}
        </template>
        <template v-if="isEmpty" #description>
          Add new payee and start making payments
        </template>
        <template v-if="isEmpty" #button>
          <base-button
            :disabled="!enabledAddNewButton || isCheckingIdentityVerification"
            :loading="isCheckingIdentityVerification"
            data-testid="empty-add-payee-button"
            class="ml-auto w-full max-w-56"
            size="sm"
            @click="
              withAccessCheck({
                callback: handleAddNewPayeeClick,
                config: { actionType: 'payees' },
              })
            "
          >
            + Add new
          </base-button>
        </template>
      </empty-state>

      <div v-else class="space-y-8">
        <div
          v-for="group in payeesGroups"
          :id="`sel-letter-${group.letter}`"
          :key="group.letter"
        >
          <div class="mb-4 font-bold text-eonx-neutral-600">
            {{ group.letter }}
          </div>

          <div class="flex flex-col gap-y-4">
            <div v-for="payee in group.payees" :key="payee.id">
              <slot name="payeeSlot" :payee="payee" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
