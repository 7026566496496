import { computed, ref } from 'vue'
import { useAccess } from '/~/composables/access/use-access'
import { usePayees } from '/~/composables/payees'
import router from '/~/router'

const { payees, allowMultiplePayees, fetchPayees, BANK_ACCOUNT_TYPE } =
  usePayees()

const searchString = ref('')
const loading = ref(false)

const searchFields = [
  'name',
  'email',
  'accountName',
  'accountNumber',
  'bsb',
  'abn',
] as const

const isEmpty = computed(() => payees.items.length === 0)
const isEmptySearch = computed(() => payeesGroups.value.length === 0)
const enabledAddNewButton = computed(() => allowMultiplePayees.value)

const filteredPayees = computed(() => {
  const searchText = searchString.value.trim().toLowerCase()

  if (!searchText) {
    return payees.items
  }
  return payees.items.filter((payee) => {
    return searchFields.some((field) =>
      payee[field]?.toLowerCase().includes(searchText)
    )
  })
})
const payeesGroups = computed(() => {
  const sortedPayees = [...filteredPayees.value].sort((a, b) =>
    ((a.name || a.accountName) ?? '').localeCompare(
      (b.name || b.accountName) ?? ''
    )
  )
  const groups = sortedPayees.reduce((acc, payee) => {
    const groupLetter = ((payee.name || payee.accountName) ??
      '')[0].toUpperCase()

    if (!acc[groupLetter]) {
      acc[groupLetter] = []
    }
    acc[groupLetter].push(payee)
    return acc
  }, {} as Record<string, typeof payees.items>)

  return Object.entries(groups).map(([letter, payees]) => ({
    letter,
    payees,
  }))
})

async function initPayeeList() {
  loading.value = true
  await fetchPayees()
  loading.value = false
}

function handleAddNewPayeeClick() {
  const { withAccessCheck } = useAccess()

  withAccessCheck({
    callback: () => {
      router.push({
        name: 'payments-new-payee',
        params: {
          origin: 'payments-payees',
          type: BANK_ACCOUNT_TYPE,
        },
      })
    },
    config: { actionType: 'payees' },
  })
}

export function usePaymentsPayeesComponent() {
  return {
    searchString,
    loading,
    isEmpty,
    payeesGroups,
    isEmptySearch,
    enabledAddNewButton,
    handleAddNewPayeeClick,
    initPayeeList,
  }
}
