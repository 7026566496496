<script setup lang="ts">
import { watchEffect } from 'vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import EmptyState from '/~/components/empty-state/empty-state.vue'
import { useAccess } from '/~/composables/access/use-access'
import { usePaymentsPayeesComponent } from './use-payments-payees-component'

const props = withDefaults(
  defineProps<{ enableContextMenu?: boolean; searchString: string }>(),
  {
    enableContextMenu: true,
  }
)

const {
  payeesGroups,
  searchString,
  isEmpty,
  isEmptySearch,
  loading,
  enabledAddNewButton,
  handleAddNewPayeeClick,
} = usePaymentsPayeesComponent()
const { withAccessCheck, isCheckingIdentityVerification } = useAccess()

watchEffect(() => {
  searchString.value = props.searchString
})
</script>

<template>
  <div>
    <div v-if="loading" class="flex-center flex items-center pb-8">
      <base-loader fullwidth size="xl" />
    </div>
    <empty-state
      v-else-if="isEmptySearch"
      icon="heroicons/solid/user"
      class="mx-auto mt-20 overflow-hidden"
    >
      <template #title>
        {{ !isEmpty ? 'No matching payees found' : 'No payees listed' }}
      </template>
      <template v-if="isEmpty" #description>
        Add new payee and start making payments
      </template>
      <template v-if="isEmpty" #button>
        <base-button
          data-testid="empty-add-payee-button"
          :disabled="!enabledAddNewButton || isCheckingIdentityVerification"
          :loading="isCheckingIdentityVerification"
          class="ml-auto w-full max-w-36"
          @click="
            withAccessCheck({
              callback: handleAddNewPayeeClick,
              config: { actionType: 'payees' },
            })
          "
        >
          + Add new payee
        </base-button>
      </template>
    </empty-state>
    <div v-else class="flex w-full grow flex-col bg-neutral-50">
      <div
        v-for="group in payeesGroups"
        :id="`sel-letter-${group.letter}`"
        :key="group.letter"
      >
        <div class="my-2.5 font-bold text-eonx-neutral-600">
          {{ group.letter }}
        </div>

        <div class="rounded-lg">
          <div v-for="payee in group.payees" :key="payee.id">
            <slot name="payeeSlot" :payee="payee" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
